<template>
  <div class="backNav" v-if="homeShow" @click="back">
      <img src="@/assets/home.png"  title="返回首页">
  </div>
</template>

<script setup>
  import {useRouter,useRoute} from 'vue-router';
  import {ref,watch} from 'vue';
  let homeShow = ref(false);
  const router = useRouter();
  const route = useRoute();

// 监听路由变化
 watch(route,(value)=>{
     if(value.fullPath.indexOf('nav') > -1){
         homeShow.value = false
     } else {
         homeShow.value = true
     }
 },{immediate:true})
//  返回导航
  const back = () =>{
     router.replace('/nav')
  }
</script>

<style lang="scss" scoped>
 .backNav{
     position:fixed;
     right: 25px;
     bottom: 25px;
     img{
         width: 30px;
         height: 30px;
         cursor: pointer;

     }
 }
</style>