let nav = {
  // 人事
  personnel: [{
      title: '部门管理',
      url: '/index/department',
      id: 0
    },
    {
      title: '员工管理',
      url: '/index/employee',
      id: 1
    },
    {
      title: '职位管理',
      url: '/index/position',
      id: 2
    }, {
      title: '店铺流水',
      url: '/index/billRunWater',
      id: 3
    }, {
      title: '市场流水',
      url: '/index/marketbillRunWater',
      id: 4
    }, {
      title: '物业流水',
      url: '/index/tenementbillRunWater',
      id: 5
    }, {
      title: '企业流水',
      url: '/index/tenementbillRunWater/runningWater',
      id: 0
    }
  ],
  // 小区物业
  property: [{
      title: '小区管理',
      url: '/index/community/commMan',
      id: 0
    },
    {
      title: '数据总览',
      url: '/index/community/dataView',
      id: 0
    },
    {
      title: '公众号绑定',
      url: '/index/community/publicNum',
      id: 0
    },
  ]
}

export default nav
