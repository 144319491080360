<template>
  <el-container class="container">
    <el-container>
      <el-aside width="175px">
        <leftNav></leftNav>
      </el-aside>
      <el-main style="min-height: 100%;">
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
  	<backNav />
</template>
<script>
import leftNav from '@/components/leftNav';
import backNav from '@/components/backNav.vue'
export default {
    components:{
        leftNav,
        backNav
    },
   data() {
 return {
}
}
}
</script>
<style lang='scss'>
.el-main{
   padding: 0 20px;
    height: calc(100vh - 50px);
  background-color: #ffffffff
}
</style>