<template lang="html">
  <div class="leftNav">
    <div class="name">组织人事管理</div>
    <div class="menu">
      <el-menu :default-active="act" class="el-menu-vertical-demo" router>
        <el-menu-item :index="item.url" v-for="item in navList[$store.state.type]" :key="item.id">
          <template #title>{{ item.title }}</template>
        </el-menu-item>
      </el-menu>
    </div>
    {{ $store.state.type }}
  </div>
</template>

<script>
import nav from '../plugin/nav.js'
export default {
  name: 'leftNav',
  data () {
    return {
      act: '/',
      userInfo: null
    };
  },
  created () {
    this.userInfo = window.localStorage.userInfo ? JSON.parse(window.localStorage.userInfo) : {}
    if (this.$store.state.type == 'personnel') {
      let arr = [];
      for (let i in nav['personnel']) {
        if (this.userInfo.userSystemType == 20 && nav['personnel'][i].id != 3) {
          arr.push(nav['personnel'][i]);
        }
        if (this.userInfo.userSystemType == 10 && nav['personnel'][i].id != 4 && nav['personnel'][i].id != 5) {
          arr.push(nav['personnel'][i]);
        }
      }
      nav['personnel'] = arr;
      this.navList = nav;
    } else {
      this.navList = nav;
    }
  },
  watch: {
    $route: {
      handler (to) {
        let arr = to.path.split('/')
        this.act = `/index/${arr[2]}`;
      },
      immediate: true
    }
  },
  methods: {
    switchover (url) {
      this.$router.push({
        path: url
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.leftNav {
  border-right: 2px solid #f4f4f4;

  .name {
    width: 100%;
    height: 57px;
    line-height: 57px;
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #dedede;
    background-color: #fff;
  }

  .menu {
    height: calc(100vh - 98px);
    overflow: auto;
    background: #FFFFFF;
  }

  .el-menu {
    border: none;
    // height: 100vh;
  }

  /deep/ .el-menu-item.is-active {
    background: #f4f6f7 !important;
    color: #010101 !important;
  }

  /deep/ .el-menu-item:hover {
    background: #f4f6f7 !important;
    color: #010101 !important;
  }

  /deep/ .el-submenu .el-submenu__title:hover {
    background: #f4f6f7 !important;
    color: #010101 !important;
  }
}
</style>
